.h-full {
    height: 100%;
}
.w-full {
    width: 100%;
}
.cursor-pointer {
    cursor: pointer;
}
.rounded-full {
    border-radius: 50%;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
.opacity-0 {
    opacity: 0;
}
