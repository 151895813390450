// add classes col-{breakpoin}-1/{columns}
@mixin make-additional-columns($columns, $breakpoints: $grid-breakpoints) {
    @each $breakpoint in map-keys($breakpoints) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        @include media-breakpoint-up($breakpoint, $breakpoints) {
            @if $columns > 0 {
                @for $i from 1 through $columns {
                    .col#{$infix}-#{$i}\/5 {
                        @include make-col($i, $columns);
                    }
                    .offset#{$infix}-#{$i}\/5 {
                        @include make-col-offset($i, $columns);
                    }
                }
            }
        }
    }
}

// add columns 1/5 2/5 3/5...
@include make-additional-columns(5);
