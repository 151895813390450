@mixin box-shadow($top: 0, $left: 0, $blur: 10px, $size: 3px) {
    box-shadow: $top $left $blur $size rgba(0, 0, 0, 0.09);
}

@mixin box-shadow-thick() {
    box-shadow: 0 0 13px 8px rgba(0, 0, 0, 0.09);
}

@mixin box-shadow-inset() {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.09);
}

@mixin box-shadow-dark-grey() {
    box-shadow: 0px 2px 10px 0px #33333326;
}
