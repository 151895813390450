@forward 'vendors-override/bootstrap-variables';
/* SCSS Variables */

//font for continental
$font-family-book: 'conti-book', Arial;
$font-family-book-italic: 'conti-book-italic', Arial;
$font-family-light: 'conti-light', Arial;
$font-family-light-italic: 'conti-light-italic', Arial;
$font-family-med: 'conti-med', Arial;
$font-family-med-italic: 'conti-med-italic', Arial;

$font-families: (
    'light': $font-family-light,
    'book': $font-family-book,
    'med': $font-family-med,
    'light-italic': $font-family-book-italic,
    'book-italic': $font-family-light-italic,
    'med-italic': $font-family-med-italic,
);

$white: #fff;
$white-tint: rgba(255, 255, 255, 0.9);
$black: #000;
$black-tint: #212121;
$orange: #ffa500;
$orange-secondary: #febb40;
$yellow: #ede521;
$yellow-tint: #ffdb99;
$green: #9cdb66;
$green-dark: #377501;
$red: #e70000;
$red-tint: #db6666;
$gray-dark: #333333;
$gray: #666666;
$gray-tint: #cdcdcd;
$gray-light: #cdcdcd87;
$light: #e0e0e0;
$disabled: #969696;
$light-green: #2db928;
$continental-light-red: #ff2d37;
$blue: #00a5dc;

//Metrics colors
$metric-best: $light-green;
$metric-best-dark: #337100;
$metric-good: #9cdb66;
$metric-good-dark: #6ea143;
$metric-okay: $orange;
$metric-okay-dark: #c3bc07;
$metric-bad: #ff2d37;
$metric-bad-dark: #941111;

$loader-regular: #eeeeee;
$loader-tint: #f5f5f5;
$loader-dark: #ececec;

$colors: (
    'white': $white,
    'white-tint': $white-tint,
    'black': $black,
    'black-tint': $black-tint,
    'orange': $orange,
    'yellow': $yellow,
    'yellow-tint': $yellow-tint,
    'green': $green,
    'green-dark': $green-dark,
    'red': $red,
    'red-tint': $red-tint,
    'gray-dark': $gray-dark,
    'gray': $gray,
    'gray-tint': $gray-tint,
    'gray-light': $gray-light,
    'gray-tint-2': $disabled,
    'light-green': $light-green,
    'continental-light-red': $continental-light-red,
    'blue': $blue,
);

$metric-colors: (
    'best': $metric-best,
    'best-dark': $metric-best-dark,
    'good': $metric-good,
    'good-dark': $metric-good-dark,
    'okay': $metric-okay,
    'okay-dark': $metric-okay-dark,
    'bad': $metric-bad,
    'bad-dark': $metric-bad-dark,
);

$loader-colors: (
    'regular': $loader-regular,
    'tint': $loader-tint,
    'dark': $loader-dark,
);

// scss-docs-start theme-color-variables
$primary: $orange !default;
$secondary-orange: $orange-secondary !default;
$secondary: $gray !default;
$success: $green !default;
$info: $gray-tint !default;
$warning: $orange-secondary !default;
$danger: $red !default;
$light: $light !default;
$dark: $gray-dark !default;
$base-color: $gray-dark;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
    'base': $base-color,
    'primary': $primary,
    'secondary-orange': $orange-secondary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'disabled': $disabled,
) !default;
// scss-docs-end theme-colors-map

//filters
$filter-yellow-color: invert(57%) sepia(61%) saturate(743%) hue-rotate(0deg) brightness(106%) contrast(103%);
$filter-black-color: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(3%) hue-rotate(176deg) brightness(96%) contrast(101%);

//shadows
$shadow-gray: rgba(0, 0, 0, 0.09);
$shadow-gray-tint: #ececec;
$shadow-black: rgba(0, 0, 0, 0.25);
$shadow-orange: rgba(255, 165, 0, 0.3);
$shadow-scrollbar: rgba(0, 0, 0, 0.17);

$shadow: (
    'gray': $shadow-gray,
    'gray-tint': $shadow-gray-tint,
    'black': $shadow-black,
    'orange': $shadow-orange,
    'scrollbar': $shadow-scrollbar,
);

$font-size-base: 1rem; // 16px

$font-size-xxs: $font-size-base * 0.5; // 8px
$font-size-xs: $font-size-base * 0.625; // 10px
$font-size-sm: $font-size-base * 0.75; // 12px
$font-size-sm1: $font-size-base * 0.8125; // 13px
$font-size-md: $font-size-base * 1.0625; // 17px
$font-size-md1: $font-size-base * 1.125; // 18px
// font size bigger, not big
$font-size-bg: $font-size-base * 1.1875; // 19px
$font-size-bg1: $font-size-base * 1.375; // 22px
$font-size-lg: $font-size-base * 1.5; // 24px
$font-size-lg1: $font-size-base * 2.125; // 34px
$font-size-xl: $font-size-base * 2.25; // 36px
//font size 14px for content
$font-size-standard: $font-size-base * 0.875; // 14px

$font-sizes: (
    'bs': $font-size-base,
    'xxs': $font-size-xxs,
    'xs': $font-size-xs,
    'sm': $font-size-sm,
    'sm1': $font-size-sm1,
    'md': $font-size-md,
    'md1': $font-size-md1,
    'bg': $font-size-bg,
    'bg1': $font-size-bg1,
    'lg': $font-size-lg,
    'lg1': $font-size-lg1,
    'xl': $font-size-xl,
    'standard': $font-size-standard,
);

$font-size-body: $font-size-md;

$h1-font-size: $font-size-base * 2.125 !default;
$h2-font-size: $font-size-lg !default;
$h3-font-size: $font-size-lg !default;
$h4-font-size: $font-size-md !default;
$h5-font-size: $font-size-md !default;
$h6-font-size: $font-size-sm !default;

$font-family-base: $font-family-book;
$headings-font-family: $font-family-base;
$headings-color: $base-color;
$headings-font-weight: normal;
$headings-font-style: normal;
$headings-margin-bottom: $font-size-sm;

$h1-font-family: $font-family-med;
$h1-font-weight: 500;
$h1-letter-spacing: 1.394px;

$h2-font-family: $font-family-book;
$h2-font-weight: 400;
$h2-letter-spacing: -0.984px;

$paragraph-margin-bottom: 0;

$enable-smooth-scroll: true;

$spacings: 1rem;
$headerHeight: 94px;
$sectionTitleHeight: ($spacings * 2) + $h2-font-size;
$dimensions: (
    'xxs': $spacings * 0.5,
    // 8px
    'xxs1': $spacings * 0.625,
    // 10px
    'xs': $spacings * 0.75,
    // 12px
    'xs2': $spacings * 0.875,
    // 14px
    'standard': $spacings,
    // 16px
    'md': $spacings * 1.25,
    // 20px
    'bg': $spacings * 1.5,
    // 24px
    'lg': $spacings * 1.75,
    // 28px
    'xl': $spacings * 2,
    // 32px,
    'content-box': calc(100vh - ($spacings * 2 * 2) - $headerHeight),
    'content-list-box': calc(var(--dimensions-content-box) - $sectionTitleHeight),
    'player-wrap': 356px,
    'player-aspect-ration': calc(9 / 16),
);

$border-radius: 20px;
