app-root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .main-container {
        min-height: 100vh;
        display: flex;
        flex-direction: column;

        > .container {
            min-height: 100vh;
            display: flex;
            flex-direction: column;

            > * {
                min-height: 100vh;
                display: flex;
                flex-direction: column;
            }

            > router-outlet {
                height: auto;
                min-height: auto;
            }
        }
    }
}
body {
    background-image: url('/assets/images/loggedBg.webp');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}
